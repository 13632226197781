import { Howl } from "howler";
import { unmute } from "./unmute";

const sound = new Howl({
  src: ["/static/media/song.webm", "/static/media/song.mp3"],
  loop: true,
});

export { sound };

const { ctx } = Howler;
if (ctx) {
  // @ts-ignore
  unmute(ctx);
}
