import { Statistics } from "../types";
import "./GameStats.css";

export default function GameStats({
  bestTimeSeconds,
  longestStrike,
  currentStrike,
}: Statistics) {
  return (
    <div className="game-stats">
      <p>
        <span>Best Time</span>
        <span>{bestTimeSeconds / 1000}</span>
      </p>
      <p>
        <span>Best Stike</span>
        <span>{longestStrike}</span>
      </p>
      <p>
        <span>Current Stike</span>
        <span>{currentStrike}</span>
      </p>
    </div>
  );
}
