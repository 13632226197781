import { Options } from "../types";

export const getScoreMultiplier = (options: Options) => {
  let scoreMultiplier = 1;

  if (options.limitTime) scoreMultiplier += 1;
  if (options.randomizeRange) scoreMultiplier += 1;
  if (options.careful) scoreMultiplier += 1;
  if (options.extendedRange) scoreMultiplier += 3;

  return scoreMultiplier;
};

export const getScore = ({
  options,
  remainingLives,
}: {
  options: Options;
  remainingLives: number;
}) => {
  const scoreMultiplier = getScoreMultiplier(options);
  return (remainingLives + 1) * scoreMultiplier;
};
