import { useEffect, useRef, useState } from "react";
import Keyboard from "./Keyboard";
import "./Form.css";
import { isMobile } from "../utils/is-mobile";

export default function Form({
  minNumber,
  maxNumber,
  onSubmit,
}: {
  minNumber: number;
  maxNumber: number;
  onSubmit: (answer: number) => void;
}) {
  const [input, setInput] = useState("");
  const [inputError, setInputError] = useState(false);
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (inputError) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => setInputError(false), 250);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [inputError]);

  const submit = () => {
    const answer = parseInt(input, 10);
    if (!input || Number.isNaN(answer)) {
      setInputError(true);
      return false;
    }

    if (answer < minNumber || answer > maxNumber) {
      setInputError(true);
      return;
    }

    setInput("");
    onSubmit(answer);
  };

  const keyHandler = (num: string | number) => {
    if (num === "submit" && !!input) {
      submit();
      return;
    }

    if (num === "reset") {
      setInput("");
      return;
    }

    const value = `${input}${num}`;

    const val = parseInt(value, 10);

    if (val.toString() !== value) {
      setInputError(true);
      return;
    }

    if (val === 0 && input === "") {
      setInputError(true);
      return;
    }

    if (val > maxNumber) {
      setInputError(true);
      return;
    }

    setInput(value);
    setInputError(false);
  };

  const formClasses = ["input-form", "justify-self-to-bottom"];
  const inputClasses = ["input-field"];
  if (!input) inputClasses.push("empty");

  if (isMobile) formClasses.push("justify-self-to-bottom");

  if (inputError) {
    inputClasses.push("error");
    formClasses.push("error");
  }

  return (
    <div className={formClasses.join(" ")}>
      <div className={inputClasses.join(" ")}>
        {input || (
          <>
            <span>{minNumber}</span>
            <span>{"< >"}</span>
            <span>{maxNumber}</span>
          </>
        )}
      </div>
      <Keyboard onClick={keyHandler} />
    </div>
  );
}
