import { Link } from "react-router-dom";
import HeartRotated from "../assets/images/heart-rotated.png";
import "./About.css";

export default function About() {
  return (
    <div className="how-to-play">
      <div className="developer">
        <h2 className="developer-title">
          Developed with <img src={HeartRotated} alt="Mad Love" width={24} /> by
        </h2>
        <p>Mehmet Yatkı</p>
        <p>Copyright © 2023</p>
      </div>
      <hr />
      <h2>Licences</h2>
      <div className="content">
        <p
          style={{
            fontSize: "12px",
            fontFamily: "monospace",
            marginBottom: "30px",
          }}
        >
          Lurking Sloth by Alexander Nakarada
          <br />
          https://www.serpentsoundstudios.com <br />
          Music promoted on
          <br />
          https://www.chosic.com/free-music/all/ <br />
          Creative Commons Attribution 4.0
          <br />
          International (CC BY 4.0) <br />
          https://creativecommons.org/licenses/by/4.0/
        </p>
      </div>
      <hr />
      <Link replace to="/">
        <button className="how-to-play-button">
          <i className="icon icon-home-1">&#xe81c;</i> Home
        </button>
      </Link>
    </div>
  );
}
