import { Link } from "react-router-dom";
import "./HowToPlay.css";
import HeartRegular from "../assets/images/heart-regular.png";
import HeartRotated from "../assets/images/heart-rotated.png";
import HeartLiar from "../assets/images/heart-liar.png";
import HeartExtra from "../assets/images/heart-extra.png";

export default function HowToPlay() {
  return (
    <div className="how-to-play">
      <h2>How To Beat Me?</h2>
      <div className="content">
        <p>
          Guess the <span>random</span> number picked!
          <br />
          Duh... 💁‍♀️
        </p>
      </div>
      <hr />
      <h2>Hearts</h2>
      <div className="content">
        <h3 className="heart-title">
          <img src={HeartRegular} alt="Friendly Heart" />
          Friendly Heart
          <img src={HeartRegular} alt="Friendly Heart" />
        </h3>
        <p>
          Your <span>trusted</span> companion.
          <br />
          She points you to the <span>right</span> direction.
        </p>
        <h3 className="heart-title">
          <img
            src={HeartRotated}
            style={{ transform: "rotate(90deg)" }}
            alt="Rotated Heart"
          />
          Disoriented Heart
          <img
            src={HeartRotated}
            style={{ transform: "rotate(180deg)" }}
            alt="Rotated Heart"
          />
        </h3>
        <p>
          He means well, but see, he is <span>rotated</span>.
          <br />
          He will tip you <span>correctly</span>,
          <br />
          but <span>from his perspective</span>.
        </p>
        <h3 className="heart-title">
          <img src={HeartLiar} alt="Liar Heart" />
          Liar Heart
          <img
            src={HeartLiar}
            style={{ transform: "rotate(-90deg)" }}
            alt="Liar Heart"
          />
        </h3>
        <p>
          Be <span>careful</span>!!! He will try to <span>trick</span> you.
          <br />
          He always tips you the <span>opposite direction</span>.
          <br />
          He will lie, even if he is <span>rotated</span>!
        </p>
        <h3 className="heart-title">
          <img src={HeartExtra} alt="Extra Heart" />
          Extra Heart
          <img src={HeartExtra} alt="Extra Heart" />
        </h3>
        <p>
          <span>77 points</span> gives you a Joker Heart.
        </p>
        <p>
          Use your Joker heart, if you want to save your <span>strike</span> ;)
        </p>
      </div>
      <hr />
      <h2>Strike</h2>
      <div className="content">
        <p>
          Number of <span>consecutive</span> correct guesses!
        </p>
      </div>
      <hr />
      <h2>Points</h2>
      <div className="content">
        <p>
          Each <span>correct</span> guess is <span>+1</span> point.
        </p>
        <p>
          Each <span>heart</span> you save is <span>+1</span> point.
        </p>
        <p>
          Points are <span>multiplied</span> by the{" "}
          <span>
            Game Level <i className="icon icon-flash">&#xe818;</i>
          </span>
          .
        </p>
      </div>
      <hr />
      <h2>
        Game Level <i className="icon icon-flash">&#xe818;</i>
      </h2>
      <div className="content">
        <p>
          Each <span>Game Option</span> increases the <span>Game Level</span>.
        </p>
      </div>
      <hr />
      <h2>Game Options</h2>
      <div className="content">
        <h3>I am fast</h3>
        <p>
          Limits time to <span>28 seconds</span>.
          <br />
          Adds <span>+1</span> to the Game Level.
        </p>
        <h3>I am smart</h3>
        <p>
          Randomizes the range
          <br />
          Adds <span>+1</span> to the Game Level.
        </p>
        <h3>I am careful</h3>
        <p>
          If you guess in the wrong direction, game is over!
          <br />
          Adds <span>+1</span> to the Game Level.
        </p>
        <h3>I am lucky</h3>
        <p>
          Doubles the range
          <br />
          Adds <span>+3</span> to the Game Level.
        </p>
      </div>
      <hr />
      <h2 style={{ marginBottom: "30px" }}>Good Luck ;)</h2>
      <hr />
      <Link replace to="/">
        <button className="how-to-play-button">
          <i className="icon icon-home-1">&#xe81c;</i> Home
        </button>
      </Link>
    </div>
  );
}
