import { createContext } from "react";
import { DeepPartial, GameData } from "../types";

interface ContextValue {
  gameData: GameData;
  setGameData: (data: DeepPartial<GameData>) => void;
}

export const defaultGameData: GameData = {
  options: {
    muted: false,
    randomizeRange: false,
    limitTime: false,
    extendedRange: false,
    careful: false,
  },
  gameStatus: {
    score: 0,
    guessPower: 0,
    isPlaying: false,
  },
  statistics: {
    totalGameCount: 0,
    totalWinCount: 0,
    longestStrike: 0,
    lastStrike: 0,
    currentStrike: 0,
    bestTimeSeconds: 0,
  },
};

export const MyContext = createContext<ContextValue>({
  gameData: {
    ...defaultGameData,
  },
  setGameData: () => {},
});
