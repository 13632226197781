import { Link, useLocation } from "react-router-dom";
import ImgLogo from "../assets/logo.svg";
import Heart from "../assets/images/heart.png";
import "./Header.css";
import { useContext, useEffect, useRef, useState } from "react";
import { MyContext } from "../contexts/GameContext";
import { getScoreMultiplier } from "../utils/get-score";
import { addClickHandler } from "../utils/click-handler";

export default function Header({
  showAudio,
  musicPlaying,
}: {
  showAudio: boolean;
  musicPlaying: boolean;
}) {
  const [showAnimation, setShowAnimation] = useState(false);
  const { gameData, setGameData } = useContext(MyContext);
  const timer = useRef<NodeJS.Timeout>();

  const location = useLocation();

  const multiplier = getScoreMultiplier(gameData.options);

  useEffect(() => {
    clearTimeout(timer.current);
    setShowAnimation(false);
    timer.current = setTimeout(() => {
      setShowAnimation(true);
    }, 10);

    return () => {
      setShowAnimation(false);
      clearTimeout(timer.current);
    };
  }, [multiplier]);

  return (
    <header>
      <div className="menu-container left">
        {showAudio && (
          <i
            id="volume-icon"
            {...addClickHandler(() => {
              setGameData({
                options: {
                  muted: !gameData.options.muted,
                },
              });
            })}
            className={`icon icon-${
              !musicPlaying ? "volume-off" : "volume-on"
            }`}
          >
            {!musicPlaying ? <>&#xe802;</> : <>&#xe801;</>}
          </i>
        )}
        {
          // @todo use isHomePage prop
        }
        {location.pathname !== "/" && (
          <Link className="home-button" replace to="/">
            <i className="icon icon-home-1">&#xe81c;</i>
          </Link>
        )}
      </div>
      <div className="logo">
        <Link className="logo" replace to="/">
          <img src={ImgLogo} alt="7guess" />
        </Link>
      </div>
      <div className="menu-container right">
        <div>
          <div className="icon-heart">
            <img src={Heart} alt="" height={24} />
            <span>+</span>
          </div>
          <span>+{gameData.gameStatus.guessPower}</span>
        </div>
        <div className={showAnimation ? "highlight-multiplier" : undefined}>
          <i className="icon icon-flash">&#xe818;</i>
          <span>x{multiplier}</span>
        </div>
      </div>
    </header>
  );
}
