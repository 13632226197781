import { useState, useEffect, useContext } from "react";
import { randomInt } from "../utils/random";

import { MyContext } from "../contexts/GameContext";
import Counter from "../components/Counter";
import { milisToMinutesAndSeconds } from "../utils/milis-to-seconds";
import { GUESS_POWER_TIER } from "../constants";
import Form from "../components/Form";
import Hearts from "../components/Hearts";
import { getScore } from "../utils/get-score";
// import { toPng } from "html-to-image";
import "./Game.css";
import { Heart, shuffleHearts } from "../utils/shuffle-hearts";
import { useCallbackPrompt } from "../hooks/useCallbackPrompt";
import GameStats from "../components/GameStats";

const RANGE = 99;

// const takeScreenshot = (attempts: number) => {
//   const pageContent = document.querySelector(".App") as HTMLElement;
//   if (!pageContent) {
//     return;
//   }

//   toPng(pageContent, { style: { margin: "0" }, backgroundColor: "#21252b" })
//     .then(async (dataUrl) => {
//       const filename = `7guess-${attempts}-tr${
//         attempts > 1 ? "ies" : "y"
//       }-${new Date().toISOString().replaceAll(":", "_")}.png`;

//       if ("share" in navigator) {
//         const blob = await (await fetch(dataUrl)).blob();
//         const filesArray = [
//           new File([blob], filename, {
//             type: blob.type,
//             lastModified: new Date().getTime(),
//           }),
//         ];
//         const shareData = {
//           files: filesArray,
//         };

//         navigator.share(shareData);
//         return;
//       }

//       var aDownloadLink = document.createElement("a");
//       aDownloadLink.download = filename;
//       aDownloadLink.href = dataUrl;
//       aDownloadLink.click();
//     })
//     .catch(function (error) {
//       console.error("oops, something went wrong!", error);
//     });
// };

export type Result = "up" | "down" | "left" | "right" | "won" | "lost";

const renderResult = (result: Result | undefined, guessPower?: number) => {
  if (!result) {
    return "start guessing!";
  }

  if (result === "lost") {
    return (
      <>
        you <span className="lost">lost</span> :(
      </>
    );
  }

  if (result === "won") {
    return (
      <>
        you <span className="won">won</span>
      </>
    );
  }

  if (result === "right" || result === "left") {
    return <>go my {result}</>;
  }

  return (
    <>
      go {result}
      {!!guessPower && <>, or use me</>}
    </>
  );
};

function Game({ musicPlaying }: { musicPlaying: boolean }) {
  const { gameData, setGameData } = useContext(MyContext);
  const [number, setNumber] = useState(0);
  const [lastScore, setLastScore] = useState("");
  const [config, setConfig] = useState({
    minNumber: 1,
    maxNumber:
      1 +
      RANGE +
      (gameData.options.extendedRange ? 100 : 0) +
      (gameData.options.randomizeRange ? randomInt(0, 27) : 0),
  });
  const [startTime, setStartTime] = useState(Date.now());
  const [endTime, setEndTime] = useState(0);
  const [answers, setAnswers] = useState<number[]>([]);
  const [result, setResult] = useState<Result | undefined>();
  const [results, setResults] = useState<number[]>([]);
  const [hearts, setHearts] = useState<Heart[]>([]);

  const isGameOver = result === "won" || result === "lost";

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    !isGameOver && !!answers.length && !!gameData.statistics.currentStrike
  );

  useEffect(() => {
    if (number === 0) {
      let { minNumber, maxNumber } = config;
      if (gameData.options.randomizeRange) {
        const offset = randomInt(1, 50);
        minNumber = offset;
        maxNumber = offset + RANGE + randomInt(0, 27);
        maxNumber += gameData.options.extendedRange ? 100 : 0;
        setConfig({
          minNumber,
          maxNumber,
        });
      }

      const num = randomInt(minNumber, maxNumber);
      setNumber(num);
      setStartTime(Date.now());
      setHearts(shuffleHearts());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  useEffect(() => {
    setGameData({
      gameStatus: {
        isPlaying: !isGameOver,
      },
    });
    return () => {
      setGameData({
        gameStatus: {
          isPlaying: false,
        },
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGameOver]);

  useEffect(() => {
    const resultsRaw = localStorage.getItem("strikes");
    if (resultsRaw) {
      try {
        setResults(JSON.parse(resultsRaw) as number[]);
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("strikes", JSON.stringify(results));
  }, [results]);

  const handleSubmit = (answer: number) => {
    answers.push(answer);
    setAnswers([...answers]);

    if (answer === number) {
      setResults([...results, answers.length].slice(-5));
      const endTime = Date.now();
      setEndTime(Date.now());

      const score = getScore({
        options: gameData.options,
        remainingLives: 7 - answers.length,
      });

      setLastScore(`+${score}`);

      gameData.gameStatus.score += score;
      if (gameData.gameStatus.score >= GUESS_POWER_TIER) {
        gameData.gameStatus.guessPower += 1;
        gameData.gameStatus.score %= GUESS_POWER_TIER;
      }

      gameData.statistics.totalGameCount += 1;
      gameData.statistics.totalWinCount += 1;
      gameData.statistics.currentStrike += 1;

      if (
        gameData.statistics.currentStrike > gameData.statistics.longestStrike
      ) {
        gameData.statistics.longestStrike = gameData.statistics.currentStrike;
      }
      const timeSpent = endTime - startTime;
      if (
        !gameData.statistics.bestTimeSeconds ||
        gameData.statistics.bestTimeSeconds > timeSpent
      ) {
        gameData.statistics.bestTimeSeconds = timeSpent;
      }

      setGameData({
        statistics: gameData.statistics,
        gameStatus: gameData.gameStatus,
      });
      return setResult("won");
    }

    if (answers.length >= 7) {
      gameData.statistics.totalGameCount += 1;
      gameData.statistics.lastStrike = gameData.statistics.currentStrike;
      gameData.statistics.currentStrike = 0;
      setGameData({
        statistics: gameData.statistics,
        gameStatus: gameData.gameStatus,
      });

      setEndTime(Date.now());
      return setResult("lost");
    }

    if (gameData.options.careful && answers.length > 1) {
      const prevAnswer = answers[answers.length - 2];
      const shouldGoDown = prevAnswer > number;
      const shouldGoUp = prevAnswer < number;

      if (
        (shouldGoDown && answer >= prevAnswer) ||
        (shouldGoUp && answer <= prevAnswer)
      ) {
        gameData.statistics.totalGameCount += 1;
        gameData.statistics.lastStrike = gameData.statistics.currentStrike;
        gameData.statistics.currentStrike = 0;
        setGameData({
          statistics: gameData.statistics,
          gameStatus: gameData.gameStatus,
        });

        setEndTime(Date.now());
        return setResult("lost");
      }
    }

    const heart = hearts[answers.length];

    let tip: Result = "up";

    if (answer > number) {
      tip = "down";
    }

    if (heart.type === "liar") {
      tip = tip === "up" ? "down" : "up";
    }

    if (heart.rotation === "90") {
      tip = tip === "up" ? "right" : "left";
    }

    if (heart.rotation === "-90") {
      tip = tip === "up" ? "left" : "right";
    }

    if (heart.rotation === "180") {
      tip = tip === "up" ? "down" : "up";
    }

    setResult(tip);
  };

  const playAgain = () => {
    setLastScore("");
    setNumber(0);
    setResult(undefined);
    setAnswers([]);
    setStartTime(Date.now());
  };

  const onTimesUp = () => {
    if (!isGameOver) {
      gameData.statistics.totalGameCount += 1;
      gameData.statistics.lastStrike = gameData.statistics.currentStrike;
      gameData.statistics.currentStrike = 0;
      setGameData({
        statistics: gameData.statistics,
      });
      setResult("lost");
      setEndTime(Date.now());
    }
  };

  return (
    <>
      {showPrompt && (
        <div className="warning-modal">
          <div>
            <p className="title">
              Leaving Game <span>?</span>
            </p>
            <p className="message">
              You will <span>lose</span> your current strike!
              <br />
              Current Stike: <span>{gameData.statistics.currentStrike}</span>
            </p>
            <p>
              <button
                className="keep-playing"
                onClick={() => {
                  cancelNavigation();
                }}
              >
                Keep Playing
              </button>
            </p>
            <p>
              <button
                className="quit-game"
                onClick={() => {
                  gameData.statistics.totalGameCount += 1;
                  gameData.statistics.lastStrike =
                    gameData.statistics.currentStrike;
                  gameData.statistics.currentStrike = 0;

                  confirmNavigation();
                }}
              >
                Quit
              </button>
            </p>
          </div>
        </div>
      )}
      {isGameOver && (
        <>
          <h2>
            {renderResult(result)}
            <span>{isGameOver ? ` ${lastScore}` : null}</span>
          </h2>
          <div>
            <h3>{number}</h3>
            <button onClick={playAgain}>Play Again</button>
          </div>
        </>
      )}
      <div className="game-play-container">
        <Hearts
          number={number}
          hearts={hearts}
          answers={answers}
          isGameOver={isGameOver}
          result={result}
          musicPlaying={musicPlaying}
          tip={renderResult(
            result,
            answers.length === 6 ? gameData.gameStatus.guessPower : 0
          )}
          onGuessPowerUsed={() => {
            answers.shift();
            gameData.gameStatus.guessPower -= 1;
            gameData.gameStatus.guessPower = Math.max(
              gameData.gameStatus.guessPower,
              0
            );
            setAnswers([...answers]);
            setGameData({
              gameStatus: gameData.gameStatus,
            });
          }}
        />
      </div>
      {!isGameOver && (
        <div>
          <Counter
            startTime={startTime}
            limit={gameData.options.limitTime ? 28 * 1000 : 0}
            onTimesUp={onTimesUp}
            pause={false}
          />
        </div>
      )}
      {isGameOver && !gameData.options.limitTime && (
        <div>
          <div className="counter">
            {milisToMinutesAndSeconds(endTime - startTime)}
          </div>
        </div>
      )}
      {isGameOver && <GameStats {...gameData.statistics} />}
      {isGameOver && result === "won" && false && (
        <button
          className="share-button"
          // onClick={() => takeScreenshot(answers.length)}
        >
          <i className="icon icon-share">&#xe826;</i>
        </button>
      )}
      {!isGameOver && (
        <Form
          minNumber={config.minNumber}
          maxNumber={config.maxNumber}
          onSubmit={handleSubmit}
        />
      )}
    </>
  );
}

export default Game;
