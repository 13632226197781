const checkIsMobile = () => {
  if ("userAgentData" in navigator) {
    // @ts-ignore
    return navigator.userAgentData.mobile;
  }

  if (window.TouchEvent) {
    return true;
  }

  // ref: https://hacks.mozilla.org/2013/04/detecting-touch-its-the-why-not-the-how/
  // https://www.geeksforgeeks.org/how-to-detect-touch-screen-device-using-javascript/
  /* browser with either Touch Events of Pointer Events
    running on touch-capable device */
  if (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    // @ts-ignore
    navigator.msMaxTouchPoints > 0
  ) {
    return true;
  }

  // if (window.innerWidth < 720) return true;

  return false;
};

export const isMobile = checkIsMobile();
