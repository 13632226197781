import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { randomInt } from "../utils/random";
import "./FlyingNumbers.css";

const size = 12;
const arr = new Array(size).fill(0);

const shuffledColors = [
  "rgba(83, 206, 236, 1)",
  "#fe6e8a",
  "#8cad20",
  "#ff9800",
  "#CCC",
];

export const FlyingNumbers = ({ bounce }: { bounce: boolean }) => {
  const [styles, setStyles] = useState<string[]>([]);
  const [numbers] = useState<number[]>(arr.map((_) => randomInt(1, 99)));
  const location = useLocation();

  useEffect(() => {
    const liStyles = arr.map((_, i) => {
      const fontSize = randomInt(24, 64);
      const boxHeight = i % 8 ? fontSize : fontSize * 2;
      const delay = i % 3 ? randomInt(0, 3) : randomInt(3, 6);
      const left = i % 2 ? randomInt(10, 40) : randomInt(60, 90);
      const duration = i % 3 ? randomInt(10, 20) : randomInt(15, 25);

      return `
        .flying-board-parts > div:nth-child(${i + 1}) {
          left: calc(${left}% - ${left > 50 ? boxHeight : -boxHeight}px);
          font-size: ${boxHeight}px;
          animation: ${duration}s linear ${delay}s infinite animateFlyingBoardParts;
          color: ${shuffledColors[i % 5]};
          opacity: 0.${randomInt(10, 30)};
          --starting-point: ${randomInt(100, 120)}vh
        }
      `;
    });
    setStyles(liStyles);
  }, [location.pathname]);

  return (
    <div className="flying-board-parts-container">
      <style>{`${styles.join("\n")}`}</style>
      <div className={`flying-board-parts ${bounce ? "bounce" : ""}`}>
        {numbers.map((number, i) => (
          <div key={`flying-board-part-${i}`}>{number}</div>
        ))}
      </div>
    </div>
  );
};
