import { useState, useEffect, useRef } from "react";
import { addClickHandler } from "../utils/click-handler";
import { isMobile } from "../utils/is-mobile";
import "./Keyboard.css";

type Key = "reset" | "submit" | number;

const numbers: Key[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, "reset", 0, "submit"];

const renderNum = (num: Key) => {
  if (typeof num === "number") return num;

  if (num === "reset") return "X";

  return "OK";
};

const getType = (num: Key): "reset" | "submit" | "button" => {
  if (typeof num === "number") return "button";

  return num;
};

const attackTimeMs = 50;

export default function Keyboard({
  onClick,
}: {
  onClick: (num: string | number) => void;
}) {
  const [activeKey, setActiveKey] = useState<string | number>("");
  const timer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (isMobile) return;

    const dekstopHandler = (e: KeyboardEvent) => {
      if (["Backspace", "Delete"].includes(e.key)) {
        onClick("reset");
        setActiveKey("reset");
        return;
      }

      if (["Enter"].includes(e.key)) {
        onClick("submit");
        setActiveKey("submit");
        return;
      }

      const num = parseInt(e.key, 10);
      if (num >= 0 && num <= 9) {
        onClick(num);
        setActiveKey(num);
      }
    };

    window.addEventListener("keydown", dekstopHandler);

    return () => {
      window.removeEventListener("keydown", dekstopHandler);
    };
  }, [onClick]);

  useEffect(() => {
    if (activeKey !== "") {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => setActiveKey(""), attackTimeMs);
    }

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [activeKey]);

  return (
    <div className="keyboard">
      {numbers.map((num) => {
        const handler: React.TouchEventHandler | React.MouseEventHandler = (
          e: React.MouseEvent | React.TouchEvent
        ) => {
          if (!isMobile) e.preventDefault();
          onClick(num);
          setActiveKey(num);
        };

        return (
          <div
            key={`key-${num}`}
            className={`button-container${activeKey === num ? " active" : ""}`}
            {...addClickHandler(handler)}
          >
            <button type={getType(num)}>{renderNum(num)}</button>
          </div>
        );
      })}
    </div>
  );
}
