export function milisToMinutesAndSeconds(milis: number) {
  // eslint-disable-next-line no-param-reassign
  if (milis < 0) milis = 0;
  const minutes = Math.floor(milis / 60000);
  const seconds = Math.ceil((milis % 60000) / 1000);
  // const ms = Math.floor((milis % 1000) / 100);
  return `${minutes < 10 ? "0" : ""}${minutes}:${
    seconds < 10 ? "0" : ""
  }${seconds}`;
}
