import { useContext, useState } from "react";
import { MyContext } from "../contexts/GameContext";
import { GUESS_POWER_TIER } from "../constants";

import "./ProgressBar.css";
import classnames from "classnames";

export default function ProgressBar() {
  const [hideCurrentScore] = useState(false);
  const { gameData } = useContext(MyContext);

  const progress = Math.ceil(
    (gameData.gameStatus.score / GUESS_POWER_TIER) * 100
  );

  if (gameData.gameStatus.isPlaying) {
    return null;
  }

  return (
    <div
      className={classnames("progress-bar", {
        reverse: progress > 60,
      })}
    >
      <div className="bar-container">
        <div className="bar-progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div
        className={`current-score ${hideCurrentScore ? "hidden" : "visible"}`}
      >
        {gameData.gameStatus.score}/{GUESS_POWER_TIER}
      </div>
    </div>
  );
}
