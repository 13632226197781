import { defaultGameData } from "../contexts/GameContext";
import { GameData } from "../types";

const gameDataKey = "game-data";

try {
} catch (err) {}

const getGameData = (): GameData => {
  const data = localStorage.getItem(gameDataKey);
  if (data) {
    const gameData = JSON.parse(data) as GameData;
    return {
      options: { ...defaultGameData.options, ...gameData.options },
      statistics: { ...defaultGameData.statistics, ...gameData.statistics },
      gameStatus: { ...defaultGameData.gameStatus, ...gameData.gameStatus },
    };
  }

  return defaultGameData;
};

const saveGameData = (gameData: GameData) =>
  localStorage.setItem(gameDataKey, JSON.stringify(gameData));

const initialGameData = getGameData();

export { getGameData, saveGameData, initialGameData };
