import { useState, useEffect, useRef } from "react";
import { milisToMinutesAndSeconds } from "../utils/milis-to-seconds";

import "./Counter.css";

type CounterProps = {
  startTime: number;
  limit: number;
  pause: boolean;
  onTimesUp: () => void;
};

const Counter: React.FunctionComponent<CounterProps> = ({
  startTime,
  limit,
  onTimesUp,
}) => {
  const [diff, setDiff] = useState(limit);

  const requestRef = useRef<number>();

  const animate = () => {
    let currentDiff = Date.now() - startTime;

    if (limit) {
      currentDiff = Math.max(limit - currentDiff, 0);
    }

    setDiff(currentDiff);

    if (limit && currentDiff <= 0) {
      onTimesUp();
      return;
    }

    requestRef.current = requestAnimationFrame(animate);
  };

  useEffect(() => {
    requestRef.current = requestAnimationFrame(animate);

    return () => {
      if (requestRef.current) cancelAnimationFrame(requestRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Make sure the effect runs only once

  return (
    <>
      <div className={`counter ${limit && diff < 5000 ? "critical" : ""}`}>
        {milisToMinutesAndSeconds(diff)}
      </div>
    </>
  );
};

export default Counter;
