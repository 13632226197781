import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Game from "./containers/Game";
import About from "./containers/About";
import HowToPlay from "./containers/HowToPlay";
import { MyContext } from "./contexts/GameContext";
import Home from "./containers/Home";

import "./App.css";
import { initialGameData, saveGameData } from "./store";
import Header from "./components/Header";
import ProgressBar from "./components/ProgressBar";
import { FlyingNumbers } from "./components/FlyingNumbers";
import { sound } from "./utils/audio";
import { DeepPartial, GameData } from "./types";

export default function App() {
  const [gameData, setGameData] = useState(initialGameData);
  const [musicLoaded, setMusicLoaded] = useState(false);
  const [musicPlaying, setMusicPlaying] = useState(false);
  const [visible, setVisibility] = useState(true);

  const mergeGameData = (
    data: DeepPartial<GameData> = {
      options: {},
      statistics: {},
      gameStatus: {},
    }
  ) => {
    setGameData((prevState) => {
      const updatedGameData = {
        options: { ...prevState.options, ...data.options },
        statistics: { ...prevState.statistics, ...data.statistics },
        gameStatus: { ...prevState.gameStatus, ...data.gameStatus },
      };
      saveGameData(updatedGameData);
      return updatedGameData;
    });
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", () => {
      setVisibility(document.visibilityState === "visible");
    });
  }, []);

  useEffect(() => {
    sound.on("load", () => {
      setMusicLoaded(true);
    });

    sound.on("play", () => {
      setMusicPlaying(true);
    });

    sound.on("pause", () => {
      setMusicPlaying(false);
    });

    sound.on("stop", () => {
      setMusicPlaying(false);
    });

    sound.on("unlock", () => {
      setMusicLoaded(true);
    });
  }, []);

  useEffect(() => {
    if (gameData.options.muted) {
      sound.stop();
      return;
    }

    if (!visible) {
      sound.pause();
      return;
    }

    sound.play();
  }, [gameData.options.muted, visible]);

  return (
    <div className="App">
      <MyContext.Provider
        value={{
          gameData,
          setGameData: mergeGameData,
        }}
      >
        <BrowserRouter>
          <Header showAudio={musicLoaded} musicPlaying={musicPlaying} />
          <ProgressBar />
          <main className="page-content">
            <Routes>
              <Route index element={<Home />} />
              <Route
                path="/play"
                element={<Game musicPlaying={musicPlaying} />}
              />
              <Route path="/how-to-play" element={<HowToPlay />} />
              <Route path="/about" element={<About />} />
            </Routes>
          </main>
          <FlyingNumbers bounce={musicPlaying} />
        </BrowserRouter>
      </MyContext.Provider>
    </div>
  );
}
