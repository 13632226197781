import { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { MyContext } from "../contexts/GameContext";
import { randomInt } from "../utils/random";
import "./Hearts.css";
import { addClickHandler } from "../utils/click-handler";
import { Heart } from "../utils/shuffle-hearts";
import { Result } from "../containers/Game";

const emptyArray = Array(7).fill(0);

export default function Hearts({
  number,
  hearts,
  result,
  answers,
  isGameOver,
  onGuessPowerUsed,
  musicPlaying,
  tip,
}: {
  number: number;
  hearts: Heart[];
  answers: number[];
  isGameOver: boolean;
  result?: Result;
  onGuessPowerUsed: () => void;
  musicPlaying: boolean;
  tip?: JSX.Element | string;
}) {
  const { gameData } = useContext(MyContext);

  const [transitions, setTransitions] = useState(
    emptyArray.map((_, index) => {
      return [randomInt(50, 250), randomInt(0, 5)];
    })
  );

  useEffect(() => {
    if (answers.length >= 7) {
      setTransitions(
        emptyArray.map((_, index) => {
          return [randomInt(50, 250), randomInt(0, 5)];
        })
      );
    }
  }, [answers.length]);

  return (
    <div
      className={classnames("hearts-container", {
        muted: !musicPlaying,
        "game-over": isGameOver,
        lost: result === "lost",
      })}
    >
      {hearts.map(({ type, rotation }, index) => {
        const [delay, scale] = transitions[index];
        const isExtraHeartActive =
          !isGameOver &&
          gameData.gameStatus.guessPower > 0 &&
          answers.length === 6 &&
          index === 6;

        let clickHandler = {};

        if (isExtraHeartActive) {
          clickHandler = addClickHandler(() => {
            onGuessPowerUsed();
          });
        }

        const heartStyle: any = {};

        if (index === 2) heartStyle["transform"] = "rotate(270deg)";

        let wrong = false;
        if (number > 0 && index > 0) {
          const shouldGoDown = answers[index - 1] > number;
          const shouldGoUp = answers[index - 1] < number;

          wrong =
            (shouldGoDown && answers[index] >= answers[index - 1]) ||
            (shouldGoUp && answers[index] <= answers[index - 1]);
        }

        return (
          <div
            key={`heart-${index}`}
            className={classnames("heart", {
              used: answers[index],
              active: answers.length === index,
              extra: isExtraHeartActive,
              wrong,
            })}
            {...clickHandler}
          >
            {!answers[index] && !isGameOver && (
              <>
                <div
                  className={classnames("speech-bubble", {
                    reverse: index >= 3,
                  })}
                >
                  {answers.length === index && <div>{tip}</div>}
                </div>
                <div className="bubbles">.</div>
              </>
            )}
            <div
              className="heart-img-container"
              style={
                rotation
                  ? // @ts-ignore
                    { "--heart-rotation": `${rotation}deg` }
                  : {}
              }
            >
              <div
                className={classnames("heart-img", type)}
                style={{
                  transitionDelay: `${delay}ms`,
                  // @ts-ignore
                  "--heartbeat-scale": `1.1${scale}`,
                }}
              />
            </div>
            {answers[index]}
          </div>
        );
      })}
    </div>
  );
}
