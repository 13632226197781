import { useContext } from "react";
import { Link } from "react-router-dom";
import { MyContext } from "../contexts/GameContext";
import "./Home.css";

export default function Home() {
  const { gameData, setGameData } = useContext(MyContext);

  return (
    <>
      <div className="justify-self-to-bottom">
        <div className="input-group">
          <label htmlFor="limitTime">I am fast</label>
          <div className="switch">
            <input
              className="switch-input"
              type="checkbox"
              id="limitTime"
              checked={gameData.options.limitTime}
              onChange={(e) => {
                setGameData({
                  options: { limitTime: e.target.checked },
                });
              }}
            />
            <label className="switch-label" htmlFor="limitTime">
              I am fast
            </label>
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="randomizeRange">I am smart</label>
          <div className="switch">
            <input
              className="switch-input"
              type="checkbox"
              id="randomizeRange"
              checked={gameData.options.randomizeRange}
              onChange={(e) => {
                setGameData({
                  options: {
                    randomizeRange: e.target.checked,
                  },
                });
              }}
            />
            <label className="switch-label" htmlFor="randomizeRange">
              I am smart
            </label>
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="careful">I am careful</label>
          <div className="switch">
            <input
              className="switch-input"
              type="checkbox"
              id="careful"
              checked={gameData.options.careful}
              onChange={(e) => {
                setGameData({
                  options: {
                    careful: e.target.checked,
                  },
                });
              }}
            />
            <label className="switch-label" htmlFor="careful">
              I am careful
            </label>
          </div>
        </div>
        <div className="input-group">
          <label htmlFor="extendedRange">I am lucky</label>
          <div className="switch">
            <input
              className="switch-input"
              type="checkbox"
              id="extendedRange"
              checked={gameData.options.extendedRange}
              onChange={(e) => {
                setGameData({
                  options: {
                    extendedRange: e.target.checked,
                  },
                });
              }}
            />
            <label className="switch-label" htmlFor="extendedRange">
              I am Lucky
            </label>
          </div>
        </div>
        <Link className="start-playing-link" replace to="/play">
          <button>Start Playing</button>
        </Link>
        <div
          style={{
            borderTop: "1px solid rgba(255,255,255,0.5)",
            paddingTop: "25px",
          }}
        >
          <div>
            <Link replace to="/how-to-play">
              <button className="how-to-play-button">How to Play?</button>
            </Link>
          </div>
          <Link replace to="/about">
            <button className="about-button">About</button>
          </Link>
        </div>
      </div>
    </>
  );
}
