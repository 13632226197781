import { randomInt } from "./random";

type Rotation = "0" | "90" | "180" | "-90";

export type Heart = {
  type: "regular" | "liar" | "rotated";
  rotation?: Rotation;
};

export const shuffleHearts = (justRegular?: boolean) => {
  if (justRegular) {
    return new Array(7).fill(0).map(() => ({ type: "regular" })) as Heart[];
  }
  const hearts: Heart[] = [];

  hearts.push({ type: "regular" });

  for (let i = 1; i <= 5; i += 1) {
    const random = randomInt(0, 100);

    if (random <= 15) {
      const rotatedLiarChance = randomInt(0, 100);
      const heart: Heart = { type: "liar" };
      if (rotatedLiarChance < 25) {
        heart.rotation = ["90", "-90"][randomInt(0, 1)] as Rotation;
      }

      hearts.push(heart);
    }

    if (random > 15 && random <= 80) {
      hearts.push({ type: "regular" });
    }

    if (random > 80) {
      const rotation = ["90", "180", "-90"][randomInt(0, 2)] as Rotation;
      hearts.push({ type: "rotated", rotation: rotation });
    }
  }

  // last one is regular to allow,
  // heart plus icon to appear
  hearts.push({ type: "regular" });

  return hearts;
};
